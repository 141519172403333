

































































































.Referral-card {
  .ref-id {
    h4 {
      margin-bottom: 8px;
      text-transform: capitalize;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      span {
        font-size: 22px;
        color: #2f394e;
        font-weight: 600;
      }
    }
  }
  .boxref {
    width: 100%;
    span {
      font-size: 17px;
      color: #6c757d;
      font-weight: 600;
    }
    .ref-link {
      margin-top: 5px;
      background: #009750;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 40px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px 0 0 5px;
          box-shadow: 0px 0px 5px 1px #00000043;
          font-weight: 550;
        }
      }
      input {
        height: 40px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 500;
        border-radius: 0px 5px 5px 0px;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
.balance-card,
.pnl-card {
  .box-info-content {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .box-right,
    .box-left {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .cash {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f394e !important;
        font-weight: 600;
        font-size: clamp(19px, 2vw, 20px);
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: clamp(0.7rem, 2vw, 0.8rem);
        text-transform: capitalize;
      }
    }
    .box-left {
    }
    .box-right {
    }
  }
}
